<template>
  <aside class="menu flex flex-column h-100 text-align-left" data-test-settings-sidebar="">
    <MetaGoLogo></MetaGoLogo>
    <div class="pl4 w5 min-w5 flex-grow-1 flex-1 overflow-y-auto f5">
      <nav class="pr3">

        <section class="setting-left-partition ">
          <div class="setting-left-partition-title ttu f5 light-silver fw4 pv2 flex items-end">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="svg-size2"
                 fill="#999">
              <path fill-rule="evenodd"
                    d="M8 2.667A2.666 2.666 0 118 8a2.666 2.666 0 110-5.333zM8 3.69a1.616 1.616 0 100 3.232A1.616 1.616 0 008 3.69zM8 8c1.502 0 4.5.893 4.5 2.667v2h-9v-2C3.5 8.893 6.498 8 8 8zm3.431 2.395c0-.427-1.76-1.4-3.431-1.4-1.67 0-3.431.973-3.431 1.4v1.345h6.862v-1.345z"></path>
            </svg>
            <span class="ml1">
            个人
          </span>
          </div>
          <div data-test="settings-profile" data-tag-id="contract-setting-left-menu-account-info" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" :class="{'concord-blue hover-concord-blue':matchPath('profile')}" @click="gotoPage('profile')">
            账号信息
          </div>
          <div data-test="settings-profile" data-tag-id="contract-setting-left-menu-group-list" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" :class="{'concord-blue hover-concord-blue':matchPath('teamManagement')}" @click="gotoPage('teamManagement')">
            团队列表
          </div>
        </section>
        <section class="setting-left-partition mt4" v-if="userInfo.isManagerRole">
          <div class="setting-left-partition-title ttu f5 light-silver fw4 pv2 flex items-end">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="svg-size2"
                 fill="#999">
              <path fill-rule="evenodd"
                    d="M8 3.667V1H1.333v12h13.334V3.667H8zm-4 8H2.667v-1.334H4v1.334zM4 9H2.667V7.667H4V9zm0-2.667H2.667V5H4v1.333zm0-2.666H2.667V2.333H4v1.334zm2.667 8H5.333v-1.334h1.334v1.334zm0-2.667H5.333V7.667h1.334V9zm0-2.667H5.333V5h1.334v1.333zm0-2.666H5.333V2.333h1.334v1.334zm6.666 8H8v-1.334h1.333V9H8V7.667h1.333V6.333H8V5h5.333v6.667zM12 6.333h-1.333v1.334H12V6.333zM12 9h-1.333v1.333H12V9z"></path>
            </svg>
            <span class="ml1">
                团队
            </span>
          </div>
          <div data-test="settings-tags" data-tag-id="contract-setting-left-menu-role" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" @click="gotoPage('user')" :class="{'concord-blue hover-concord-blue':matchPath('user')}">
            用户
          </div>
          <div data-test="settings-tags" data-tag-id="contract-setting-left-menu-role" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" @click="gotoPage('role')" :class="{'concord-blue hover-concord-blue':matchPath('role')}">
            角色
          </div>
          <div data-test="settings-tags" data-tag-id="contract-setting-left-menu-tag" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100">
            <el-tooltip placement="right" content="开发中">
              <span>标签</span>
            </el-tooltip>
          </div>
          <div data-test="settings-upload" data-tag-id="contract-setting-left-menu-bulk-upload" class=" ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" :class="{'concord-blue hover-concord-blue':matchPath('bulk-upload')}" @click="gotoPage('bulkUpload')">
            批量上传
          </div>
          <div data-test="settings-tags" data-tag-id="contract-setting-left-menu-account-organization" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" @click="gotoPage('organization')" :class="{'concord-blue hover-concord-blue':matchPath('organization')}">
            组织架构
          </div>
<!--          <div data-test="settings-tags" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" @click="gotoPage('dataDictionary')" :class="{'concord-blue hover-concord-blue':matchPath('dataDictionary')}">-->
<!--            数据字典-->
<!--          </div>-->
          <div data-test="settings-tags" data-tag-id="contract-setting-left-menu-account-template-category" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" @click="gotoPage('publishedTemplateCategory')" :class="{'concord-blue hover-concord-blue':matchPath('publishedTemplateCategory')}">
            模板类别
          </div>
          <div data-test="settings-tags" data-tag-id="contract-setting-left-menu-role" class="ember-view hover-concord-dark-blue no-underline pv1 dib mid-gray w-100" @click="gotoPage('order')" :class="{'concord-blue hover-concord-blue':matchPath('order')}">
            订单
          </div>
        </section>

      </nav>
    </div>
    <leftMenuFooter ref="leftMenuFooter"></leftMenuFooter>

  </aside>

</template>

<script>

import leftMenuFooter from "./LeftMenuFooter";
import MetaGoLogo from "./universalUI/MetaGoLogo";
import {BehaviorPermission, checkUserBehaviorPermission} from "../components/common/permissionUtil";
export default {
  name: "ContractSettingLeftMenu",
  components: {MetaGoLogo, leftMenuFooter},
  props: ["activeIndex"],
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      BehaviorPermission,
      checkUserBehaviorPermission,
      refreshCount: false
    }
  },
  watch:{
    activeIndex: {
      handler(val){
        this.$nextTick(() => {
          this.$refs["leftMenuFooter"].changeActiveIndex(val)
        })
      },
      deep: true,
      immediate: true
    },
    refreshCount: {
      handler(){
        this.$nextTick(() => {
          this.$refs["leftMenuFooter"].initMessagesCount()
          this.$refs["leftMenuFooter"].initTableData()
          this.refreshCount = false
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    matchPath(path){
      return this.$route.path.indexOf(path)>-1
    },
    gotoPage(name){
       this.$router.push({
        name: name
      })
    }
  },
}
</script>

<style scoped>
@import "/src/assets/css/leftSideMenu.css";
/*@import "../assets/css/contract.css";*/
.ember-view{
  cursor: pointer;
}
.setting-left-partition:first-child .setting-left-partition-title{
  padding-top: 0;
}
</style>
